import { DeviceStatus, MailTemplateType } from 'interfaces/api';

import exchange from './exchange/messages';
import order from './order/messages';
import reports from './reports/messages';
import server from './server/messages';
import settings from './settings/messages';
import store from './store/messages';
import users from './users/messages';

export default {
  exchange,
  order,
  reports,
  server,
  settings,
  store,
  users,
  pages: {
    users: 'User Management',
    productFeatures: 'Products & Features',
    groups: 'Groups',
    laboratoryGroups: 'Laboratory Groups',
    doctorGroups: 'Doctor Groups',
    designer: 'Report Designer',
    labconnect: 'Labconnect',
    print: 'Report Print',
    settings: 'Settings',
    server: 'Server',
  },
  generalSettings: 'General Settings',
  doctorSettings: 'Doctor Settings',
  patientSettings: 'Patient Settings',
  preferences: 'Preferences',
  laboratoryGroupBarcodes: 'Laboratory group barcodes',
  advancedAlphanumericBarcodes: 'Advanced alphanumeric barcodes',
  notificationOptions: {
    title: 'Choose Notification Options',
    description: 'Please optionally enter contact details of the body to be notified',
  },
  realReRequest: 'Real re-request',
  userPreferences: {
    label: 'User preferences',
    reset: {
      label: 'Reset Defaults',
      confirm: {
        title: 'Attention!',
        content: 'Do you really want to reset the user preferences?',
      },
    },
  },
  products: {
    doctorsEnabled: 'Enabled for doctors',
    patientsEnabled: 'Enabled for patients',
    appEnabled: 'Enabled for app',
    explicitLock: 'Explicit Lock',
    description: {
      enableExplicit: 'This product is disabled by default for this user type.',
      disableExplicit: 'This product is enabled by default for this user type.',
    },
  },
  features: {
    description: {
      enableExplicit: 'This feature is disabled by default for this user type.',
      disableExplicit: 'This feature is enabled by default for this user type.',
      enabledAll: 'This feature is generally enabled for this user type.',
      disabledAll: 'This feature is generally disabled for this user type.',
    },
  },
  mails: {
    header: 'E-Mails',
    smtp: 'SMTP',
    groups: 'Groups',
    templates: 'Templates',
    types: {
      [MailTemplateType.AcceptedOrder]: 'Accepted Order',
      [MailTemplateType.ActivatePatient]: 'Activate Patient',
      [MailTemplateType.ActivateDoctor]: 'Activate Doctor',
      [MailTemplateType.DoctorFirstLogin]: 'Doctor First Login',
      [MailTemplateType.AutomaticReportSend]: 'Automatic Report Send',
      [MailTemplateType.ContactForm]: 'Contact Form',
      [MailTemplateType.SendReport]: 'Send Report',
      [MailTemplateType.SendReportCopyToMe]: 'Copy To Me',
      [MailTemplateType.DeviceIdActivated]: 'Device Id Activated',
      [MailTemplateType.DeviceIdDeactivated]: 'Device Id Deactivated',
      [MailTemplateType.MessageToLab]: 'Message to Lab',
      [MailTemplateType.NewDeviceId]: 'New Device Id',
      [MailTemplateType.NewOrderLS]: 'New Order',
      [MailTemplateType.NewPassword]: 'New Password',
      [MailTemplateType.NewReport]: 'New Report',
      [MailTemplateType.CriticalResult]: 'Critical result',
      [MailTemplateType.PathologicalResult]: 'Pathological result',
      [MailTemplateType.CustomPush]: 'Custom Push',
      [MailTemplateType.PasswordPdf]: 'PDF Password',
      [MailTemplateType.Reactivate]: 'Reactivate',
      [MailTemplateType.ReactivatePatient]: 'Reactivate patient',
      [MailTemplateType.ReactivateDoctor]: 'Reactivate doctor',
      [MailTemplateType.ResendWelcome]: 'Resend welcome',
      [MailTemplateType.ResendWelcomePatient]: 'Resend welcome (patient)',
      [MailTemplateType.ResendWelcomeDoctor]: 'Resend welcome (doctor)',
      [MailTemplateType.ResetPassword]: 'Reset Password',
      [MailTemplateType.ResetPasswordMissingSmsNumber]: 'Reset Password (missing SMS number)',
      [MailTemplateType.ChangeOrderRequest]: 'Request_for_req',
      [MailTemplateType.RequestToLab]: 'request_to_lab',
      [MailTemplateType.RequestToLabMobile]: 'request_to_lab_mobile',
      [MailTemplateType.RequestToLabMobileConfirmation]: 'request_to_lab_mobile_authenticate',
      [MailTemplateType.SampleInLaborNotification]: 'Sample in laboratory',
      [MailTemplateType.RealRerequestApplied]: 'Additional request(s) applied',
      [MailTemplateType.RealRerequestMade]: 'Additional request(s) accepted',
      [MailTemplateType.RealRerequestStatement]: 'Real re-request statement',
      [MailTemplateType.WebshopOrderConfirmation]: 'webshop_order_confirmation',
      [MailTemplateType.Notification]: 'New Notification',
      [MailTemplateType.NewStoreOrderInfo]: 'New Labstore Order Info',
      [MailTemplateType.NewStoreOrderConfirmation]: 'New Labstore Order Confirmation',
    },
    typeGroups: {
      user: 'Users',
      reports: 'Reports',
      orders: 'Orders',
      labstore: 'Labstore',
      general: 'General',
    },
  },
  devices: {
    title: 'Devices',
    form: {
      name: 'Name',
      note: 'Note',
      expiration: 'Expiration (in hours)',
    },
    controls: {
      create: {
        label: 'Create API Key',
        success: {
          title: 'API Authentication Token',
          content: 'This is your token. Please remember it.',
        },
      },
      note: {
        label: 'Set Device Note',
      },
      createLabconnect: {
        label: 'Labconnect registration',
        success: {
          title: 'Labconnect registration token',
          content: 'Use this token as password in the Labconnect installer. The token will not be displayed again, expires automatically and cannot be revoked. A new token can be created any time, previous tokens are valid until expiration.',
        },
      },
      approve: {
        label: 'Approve device',
        confirm: {
          title: 'Confirm approval',
          content: 'Do you really want to approve this device?',
        },
      },
      deny: {
        label: 'Deny device',
        confirm: {
          title: 'Confirm deny',
          content: 'Do you really want to deny this device?',
        },
      },
      delete: {
        label: 'Delete device',
        confirm: {
          title: 'Confirm delete',
          content: 'Do you really want to delete this device?',
        },
      },
      revoke: {
        label: 'Revoke login',
        confirm: {
          title: 'Confirm revocation',
          content: 'Do you really want to revoke this device?',
        },
      },
    },
    lastUsed: 'last used {TIME}',
    neverUsed: 'never used',
    status: {
      [DeviceStatus.Approved]: 'Approved',
      [DeviceStatus.Pending]: 'Pending',
      [DeviceStatus.Denied]: 'Denied',
      [DeviceStatus.Revoked]: 'Revoked',
    },
  },
  cases: {
    general: {
      casesOrderListOpened: 'Order list opened',
      disabledReportLaboratoryGroups: 'Disabled report laboratory groups',
    },
  },
};
