import React from 'react';
import { Button, Container, Icon, Modal } from 'components';
import { Translate } from 'providers/IntlProvider';
import messages from 'messages';
import { useBiometrics } from 'providers/BiometricsProvider';
import './FaceIdRequest.less';
import { faFaceViewfinder, faFingerprint } from '@fortawesome/pro-thin-svg-icons';

const labels = messages.auth.login;

export const FaceIDRequest: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const biometrics = useBiometrics();

  return (
    <>
      {children}
      <Modal
        narrow={true}
        open={biometrics.faceIdRequestVisible}
        className={'faceId-modal'}
        footer={
          (
            <Container grow shrink center className={'face-id-container'}>
              <Button
                block
                type={'primary'}
                onClick={() => {
                  biometrics.enable();
                  biometrics.hideFaceIdRequest();
                }}
              >
                <Translate message={labels.faceIdRequest.useBiometrics}/>
              </Button>
              <a onClick={() => biometrics.hideFaceIdRequest()} className={'postpone-face-id margin-top-1'}>
                <Translate message={labels.faceIdRequest.postponeUseBiometrics}/>
              </a>
            </Container>
          )
        }
      >
        <Container grow shrink center className={'center faceId-container padding-top-3'} padding>
          <h1><Translate message={labels.biometrics}/></h1>
          <div className={'icon-wrapper padding-2'}>
            <Icon className={'biometrics-icon'} icon={faFingerprint} size={'4x'}/>
            <Icon className={'biometrics-icon'} icon={faFaceViewfinder} size={'4x'}/>
          </div>
          <p><Translate message={labels.faceIdRequest.description}/></p>
          <p><Translate message={labels.faceIdRequest.notice}/></p>
        </Container>
      </Modal>
    </>
  );
};
